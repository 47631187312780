import React, { ReactElement, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ISuggestionListItemsProps } from '../../interfaces/SuggestionListItemsProps';
import makeKeywordsBold from '../../utils/makeKeywordsBold';
import renderHtml from '../../utils/renderHtml';

/**
 * Create the suggestion item list for the suggestions list
 *
 * @param props
 * @returns
 */
const SuggestionListItem = (props: ISuggestionListItemsProps):ReactElement => {
  const {
    focus,
    intervention,
    keyword,
  } = props;
  const refLink = useRef<HTMLAnchorElement>(null);

  /**
   * Perform side effects in function components.
   * In this way it handle async functionality
   * If focus props is set it will set the focus on the Link element
   */
  useEffect(() => {
    if (focus && refLink && refLink.current) {
      refLink.current.focus();
    }
  }, [focus]);

  return (
    <li
      className="c-suggestion-list__item"
      role="option"
      tabIndex={-1}
      aria-selected="false"
    >
      <Link
        to={`/InterventionDetails/${intervention.code}`}
        tabIndex={-1}
        ref={refLink}
      >
        { renderHtml(makeKeywordsBold(intervention.naam, keyword), true) }
      </Link>
    </li>
  );
};

export default SuggestionListItem;
