import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import ErrorComponent from '../errorComponent/ErrorComponent';
import { interventionsIdGetService } from '../../services/interventionsService/interventionsService';
import { InterventionDetail } from '../../interfaces/intervention-api/interventionDetails';
import { DEFAULT_APPLICATION_TEXT, LANGUAGE } from '../../constants/constants';

import './InterventionDetailComponent.scss';
import SchoolInterventionDetails from './SchoolInterventionDetails';
import HealthyLifeCounterDetail from './HealthyLifeInterventionDetail';
import { IInterventionDetailComponentProps } from '../../interfaces/InterventionDetailComponentProps';
import OverviewNavigationButton from '../OverviewNavigationButton/OverviewNavigationButton';
import getMappingFileConfiguration from '../../utils/mapperHelper';
import { IConfigMapping } from '../../interfaces/ConfigMapping';
import ShareInterventionsButton from '../ShareInterventionsButton/ShareInterventionsButton';

/**
 * InterventionDetailComponent get the detail intervention data from the interventions web API
 * and build the detail page and render it in the DOM
 */
const InterventionDetailComponent = (props: IInterventionDetailComponentProps): ReactElement => {
  const {
    mappingFileName,
  } = props;
  const { iId } = useParams<{ iId: string }>();
  const { trackPageView, trackEvent } = useMatomo();
  const [error, setError] = useState(false);
  const [intervention, setIntervention] = useState<InterventionDetail | null>(null);
  const [loading, setLoading] = useState(true);
  const [configuration, setConfiguration] = useState<IConfigMapping>();

  const {
    ERROR_MESSAGE_INTERVENTION_DETAIL,
    LOADING_MESSAGE_INTERVENTION_DETAIL,
  } = DEFAULT_APPLICATION_TEXT[LANGUAGE];

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      trackPageView({});
    }
  }, [trackPageView]);

  /**
   * Perform side effects in function components.
   * In this way it handle async functionality.
   * Get the filters JSON from Drupal on render of this component
   *
   */
  useEffect(() => {
    if (mappingFileName) {
      const getMappingConfigAsync = async () => {
        try {
          const response = await getMappingFileConfiguration<IConfigMapping>(mappingFileName);
          setConfiguration(response);
        } catch (err) {
          setError(true);
        }
      };
      getMappingConfigAsync();
    } else {
      setError(true);
    }
  }, [mappingFileName]);

  /**
   * Render the Intervention detail page
   *
   * @returns
   */
  const renderInterventionDetail = (interventionDetail: InterventionDetail): ReactElement | null => {
    if (!configuration?.siteName) {
      setError(true);
    }

    if (configuration?.siteName === 'gezondeschool') {
      return (
        <SchoolInterventionDetails iId={iId} interventionDetail={interventionDetail} />
      );
    }
    return (
      <HealthyLifeCounterDetail iId={iId} interventionDetail={interventionDetail} />
    );
  };

  /**
   * Perform side effects in function components.
   * In this way it handle async functionality
   * On load of the page it will get the iId from the URL and get the intervention data
   */
  useEffect(() => {
    if (mappingFileName && loading) {
      const asyncArrowFunction = async () => {
        try {
          const interventionResponse = await interventionsIdGetService(iId);
          if (typeof interventionResponse !== 'number') {
            setIntervention(interventionResponse);
          }
          setLoading(false);
        } catch (err) {
          if (process.env.NODE_ENV !== 'development') {
            const te = {
              action: 'Click',
              category: 'Error',
              name: 'Get Service Interventies Detail',
            };
            trackEvent(te);
          }
          setError(true);
        }
      };
      asyncArrowFunction();
    }
  }, [loading, trackEvent, iId, mappingFileName]);

  useEffect(() => {
    if (intervention) {
      document.title = intervention.naam ?? 'Detail';
    }
  }, [intervention]);

  if (error) {
    return (
      <>
        <OverviewNavigationButton />
        <ErrorComponent errorMessage={ERROR_MESSAGE_INTERVENTION_DETAIL} />
      </>
    );
  }

  return (
    <>
      <div className="container pr-sm-3 pt-0">
        <div className="row">
          {
              configuration?.showBackButton
              && (
              <div className="col-lg-3 col-md-6 col-sm-6">
                <OverviewNavigationButton />
              </div>
              )
            }
          <div className="col-lg-3 col-md-6 col-sm-6">
            <ShareInterventionsButton />
          </div>
        </div>
      </div>
      { loading ? LOADING_MESSAGE_INTERVENTION_DETAIL : renderInterventionDetail(intervention as InterventionDetail) }
    </>
  );
};

export default InterventionDetailComponent;
