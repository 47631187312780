import React, { ReactElement } from 'react';
import { IDescriptionItemProps } from '../../interfaces/DescriptionItemProps';

/**
 * Render the description item for the description list
 * If description is null it will not render a description item
 *
 * @param props
 * @returns
 */
const DescriptionItem = (props: IDescriptionItemProps): ReactElement | null => {
  const {
    addClassName,
    description,
    term,
  } = props;

  if (description) {
    return (
      <>
        <dt className={addClassName}>{ term }</dt>
        <dd className={addClassName}>{ description }</dd>
      </>
    );
  }
  return null;
};

export default DescriptionItem;
