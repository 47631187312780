import React from 'react';
import { IClassGroupsProps } from '../../interfaces/ClassGroupsProps';
import headingParagraphElement from '../../utils/headingParagraphElement';

const ClassGroups = (props: IClassGroupsProps): JSX.Element | null => {
  const {
    schoolGeneral,
    specialEducation,
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapChoices = (choiceList: any[]): string[] | null => {
    if (choiceList && choiceList.length > 0) {
      return choiceList.map((choice, index) => `${choice.tekst ?? choice.Tekst}${index !== choiceList.length - 1 ? ', ' : ''}`);
    }
    return null;
  };

  const getClassGroups = () => {
    const elements = [];

    if (schoolGeneral && schoolGeneral.boOnderwijsTypeKeuzes.length > 0) {
      elements.push((
        headingParagraphElement('Primair onderwijs', mapChoices(schoolGeneral.boOnderwijsTypeKeuzes))
      ));
    }
    if (specialEducation) {
      if (specialEducation.DoelgroepI.length > 0) {
        elements.push((
          headingParagraphElement('(Voortgezet) speciaal onderwijs', mapChoices(specialEducation.DoelgroepI))
        ));
      }
      if (specialEducation.DoelgroepII.length > 0) {
        elements.push((
          headingParagraphElement('Clusters', mapChoices(specialEducation.DoelgroepII))
        ));
      }
      if (specialEducation.LeeftijdVanaf && specialEducation.LeeftijdTotEnMet) {
        elements.push((
          // eslint-disable-next-line max-len
          headingParagraphElement('Speciaal onderwijs leeftijd', `${specialEducation.LeeftijdVanaf} tm. ${specialEducation.LeeftijdTotEnMet}`)
        ));
      }
    }
    if (schoolGeneral && schoolGeneral.poOnderwijsTypeKeuzes.length > 0) {
      elements.push((
        headingParagraphElement('Praktijkonderwijs', mapChoices(schoolGeneral.poOnderwijsTypeKeuzes))
      ));
    }
    if (schoolGeneral && schoolGeneral.vmboBkOnderwijsTypeKeuzes.length > 0) {
      elements.push((
        headingParagraphElement('Vmbo-b/k', mapChoices(schoolGeneral.vmboBkOnderwijsTypeKeuzes))
      ));
    }
    if (schoolGeneral && schoolGeneral.vmboTlOnderwijsTypeKeuzes.length > 0) {
      elements.push((
        headingParagraphElement('Vmbo-t', mapChoices(schoolGeneral.vmboTlOnderwijsTypeKeuzes))
      ));
    }
    if (schoolGeneral && schoolGeneral.havoOnderwijsTypeKeuzes.length > 0) {
      elements.push((
        headingParagraphElement('Havo', mapChoices(schoolGeneral.havoOnderwijsTypeKeuzes))
      ));
    }
    if (schoolGeneral && schoolGeneral.vwoOnderwijsTypeKeuzes.length > 0) {
      elements.push((
        headingParagraphElement('Vwo', mapChoices(schoolGeneral.vwoOnderwijsTypeKeuzes))
      ));
    }
    if (schoolGeneral && schoolGeneral.indicatieKostenMbo !== null) {
      elements.push((
        headingParagraphElement('Mbo', '')
      ));
    }

    if (elements.length > 0) {
      return (
        <>
          {
            elements.map((el) => el)
          }
        </>
      );
    }

    return null;
  };

  return getClassGroups();
};

export default ClassGroups;
