import React from 'react';
import { IUnorderedListOfObjectTextProps } from '../../interfaces/UnorderedListOfObjectTextProps';

const UnorderedListOfObjectText = (props: IUnorderedListOfObjectTextProps): JSX.Element | null => {
  const { objectList } = props;

  const getGuid = () => (Date.now().toString(36) + Math.random().toString(36).substring(2));
  if (objectList && objectList.length > 0) {
    const listItems = objectList.map((object) => <li key={getGuid()}>{object.tekst ?? object.Tekst}</li>);
    return (
      <ul data-testid={`${objectList[0].code}-${objectList.length}`} key={`${getGuid()}`}>
        {
          listItems
        }
      </ul>
    );
  }

  return null;
};

export default UnorderedListOfObjectText;
