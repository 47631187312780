import React from 'react';
import { IThemesListItemsProps } from '../../interfaces/ThemesListItemsProps';
import UnorderedListOfObjectText from './UnorderedListOfObjectText';

const ThemesListItems = (props: IThemesListItemsProps): JSX.Element | null => {
  const {
    baseThemes,
  } = props;
  if (baseThemes) {
    const mentalHealthThemes = <UnorderedListOfObjectText objectList={baseThemes.geestelijkThemaKeuzes} />;
    const lifeStyleThemes = <UnorderedListOfObjectText objectList={baseThemes.leefstijlThemaKeuzes} />;
    const bodyThemes = <UnorderedListOfObjectText objectList={baseThemes.lichamelijkThemaKeuzes} />;
    const environmentThemes = <UnorderedListOfObjectText objectList={baseThemes.milieuThemaKeuzes} />;
    const otherThemes = <UnorderedListOfObjectText objectList={baseThemes.overigThemaKeuzes} />;
    const sexualityThemes = <UnorderedListOfObjectText objectList={baseThemes.seksualiteitThemaKeuzes} />;
    const socialThemes = <UnorderedListOfObjectText objectList={baseThemes.sociaalThemaKeuzes} />;
    return (
      <>
        {mentalHealthThemes}
        {lifeStyleThemes}
        {bodyThemes}
        {environmentThemes}
        {otherThemes}
        {sexualityThemes}
        {socialThemes}
      </>
    );
  }

  return null;
};

export default ThemesListItems;
