/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { ReactElement } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const headingParagraphElement = (heading: string, paragraph: any): ReactElement => {
  const getGuid = () => (Date.now().toString(36) + Math.random().toString(36).substring(2));
  return (
    <div key={`${getGuid()}`}>
      <h4 style={{ marginBottom: '0' }}>{heading}</h4>
      <p style={{ marginTop: '2px', marginBottom: '10px' }}>{paragraph}</p>
    </div>
  );
};

export default headingParagraphElement;
