import React, { ReactElement, useEffect, useState } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useHistory } from 'react-router-dom';
import Grid from './Grid';
import ErrorComponent from '../errorComponent/ErrorComponent';
import SearchBarComponent from '../searchBarComponent/SearchBarComponent';
import { DEFAULT_APPLICATION_TEXT, LANGUAGE } from '../../constants/constants';
import getMappingFileConfiguration from '../../utils/mapperHelper';
import { IInterventionsOverviewComponentProps } from '../../interfaces/InterventionsOverviewComponentProps';
import { IConfigMapping } from '../../interfaces/ConfigMapping';

/**
 * Render the interventions overview component
 *
 * @returns
 */
const InterventionsOverviewComponent = ({ mappingFileName }: IInterventionsOverviewComponentProps): ReactElement => {
  const [loading, setLoading] = useState(true);
  const { trackEvent } = useMatomo();
  const [error, setError] = useState(false);
  const [configData, setConfigData] = useState<IConfigMapping>();
  const history = useHistory();

  const {
    ERROR_MESSAGE_INTERVENTION_THEMES,
    LOADING_MESSAGE_INTERVENTION_THEMES,
    OVERVIEW_TITLE,
  } = DEFAULT_APPLICATION_TEXT[LANGUAGE];

  /**
   * Get the mapping json from the drupal page
   */
  useEffect(() => {
    if (loading) {
      const asyncArrowFunction = async () => {
        try {
          const subSiteStrings = await getMappingFileConfiguration<IConfigMapping>(mappingFileName);
          setConfigData(subSiteStrings);
        } catch (err) {
          if (process.env.NODE_ENV !== 'development') {
            const te = {
              action: 'Click',
              category: 'Error',
              name: 'Get Interventies Overview Mapping File',
            };
            trackEvent(te);
          }
          setError(true);
        } finally {
          setLoading(false);
        }
      };
      asyncArrowFunction();
    }
  }, [loading, trackEvent, mappingFileName]);

  useEffect(() => {
    document.title = OVERVIEW_TITLE;
  }, [OVERVIEW_TITLE]);

  /**
   * Get grid tiles for the interventions overview
   */
  const renderGrid = () => {
    if (configData && configData.configFilters.length > 0) {
      return (
        configData.configFilters.map((config) => {
          if (config.showInOverview) {
            return (
              <Grid key={config.overviewTitle} overviewConfig={config} />
            );
          }
          return null;
        })
      );
    }
    return null;
  };

  /**
   * Method need to be passed through as paramter to the child component search bar
   * On click of the button search it will navigate to another page
   *
   * @param searchTermOnClick
   */
  const onSearchClick = (searchTermOnClick: string) => {
    const searchParams = new URLSearchParams({ page: '1' });
    if (searchTermOnClick) {
      searchParams.append('titleSearch', searchTermOnClick);
    } else {
      searchParams.append('allFilter', 'allInterventions');
    }
    history.push({
      pathname: '/InterventionSearchResults',
      search: searchParams.toString(),
    });
  };

  if (error) {
    return (
      <ErrorComponent errorMessage={ERROR_MESSAGE_INTERVENTION_THEMES} />
    );
  }

  return (
    <div className="d-flex flex-column">
      <div className="mb-4">
        {
          configData?.showSearchBar
          && <SearchBarComponent onSearchClick={onSearchClick} />
        }
      </div>
      <div>
        { loading ? LOADING_MESSAGE_INTERVENTION_THEMES : renderGrid() }
      </div>
    </div>
  );
};

export default InterventionsOverviewComponent;
