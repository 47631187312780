import React, { ReactElement } from 'react';
import { ReactComponent as EffectivenessIcon } from '../../assets/effectiveness.svg';
import { RECOGNITION_LEVELS } from '../../constants/constants';
import { IEffectivenessComponentProps } from '../../interfaces/EffectivenessComponentProps';

import './EffectivenessComponent.scss';

/**
 * Render the effectiveness component with the correct level and styling
 * - "Goed beschreven" is level 1
 * - "Goed onderbouwd" is level 2
 * - "Eerste aanwijzingen voor effectiviteit" is level 3
 * - "Goede aanwijzingen voor effectiviteit" is level 4
 * - "Sterke aanwijzingen voor effectiviteit" is level 5
 *
 * @param props
 * @returns
 */
const EffectivenessComponent = (props: IEffectivenessComponentProps): ReactElement => {
  const { recognitionLevel } = props;

  /**
   * Map the recognition level from the text wat is set in the intervention
   *
   * @param recognitionLevelText
   * @returns
   */
  const getRecognitionLevel = (recognitionLevelText: string | null | undefined): number => {
    const rLevel = RECOGNITION_LEVELS.find((recognition) => recognition.tekst === recognitionLevelText);
    return rLevel?.level || 0;
  };

  return (
    <div className="c-effectiveness d-flex flex-column align-items-center">
      <div
        data-testid="effectiveness-icon"
        className={`c-effectiveness__icon c-effectiveness__icon--level-${getRecognitionLevel(recognitionLevel?.tekst)}`}
      >
        <EffectivenessIcon />
      </div>
      <p data-testid="effectiveness-text" className="c-effectiveness__text">{ recognitionLevel?.tekst }</p>
    </div>
  );
};

export default EffectivenessComponent;
