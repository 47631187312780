import React, { ReactElement } from 'react';
import { IErrorComponentProps } from '../../interfaces/ErrorComponentProps';

import './ErrorComponent.scss';

/**
 * Render the error component
 *
 * @param props
 * @returns
 */
const ErrorComponent = (props: IErrorComponentProps): ReactElement => {
  const { errorMessage } = props;

  return (
    <div role="alert" className="alert alert-danger c-error">
      <span className="glyphicon glyphicon-exclamation-sign c-error__icon" aria-hidden="true" />
      <span>{ errorMessage }</span>
    </div>
  );
};

export default ErrorComponent;
