import React from 'react';
import ReactDOM from 'react-dom';
import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';
import './index.scss';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import InterventionsComponent from './components/interventionsComponent/InterventionsComponent';
import InterventionDetailComponent from './components/interventionDetailComponent/InterventionDetailComponent';
import InterventionsOverviewComponent from './components/interventionsOverviewComponent/InterventionsOverviewComponent';
// import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('interventions');
// add special class so it will not be overridden by other css styling on the page
rootElement?.classList.add('interventions-application');

/**
 * Get the value from the data attribute on the React root element
 *
 * @returns
 */
const getDataValue = (name: string): string => rootElement?.dataset[name] || '';
const instance = createInstance({
  urlBase: 'https://statistiek.rijksoverheid.nl/piwik/',
  siteId: +getDataValue('matomoSiteId'),
});

ReactDOM.render(
  <React.StrictMode>
    <Router basename="/">
      <Switch>
        <Route exact path="/">
          <Redirect to="/Overview" />
        </Route>
        <Route path="/Overview">
          <MatomoProvider value={instance}>
            <InterventionsOverviewComponent mappingFileName={getDataValue('configMappingJson')} />
          </MatomoProvider>
        </Route>
        <Route path="/InterventionSearchResults">
          <MatomoProvider value={instance}>
            <InterventionsComponent mappingFileName={getDataValue('configMappingJson')} />
          </MatomoProvider>
        </Route>
        <Route path="/InterventionDetails/:iId">
          <MatomoProvider value={instance}>
            <InterventionDetailComponent
              mappingFileName={getDataValue('configMappingJson')}
            />
          </MatomoProvider>
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  rootElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
