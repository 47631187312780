import React, { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import EffectivenessComponent from '../effectivenessComponent/EffectivenessComponent';
import DescriptionItem from '../interventionDetailComponent/DescriptionItem';
import Interventie from '../../interfaces/intervention-api/interventie';
import { IInterventionsListProps } from '../../interfaces/InterventionsListProps';

import './InterventionsList.scss';

/**
 * Create the interventions list for the Interventions component
 *
 * @param props
 * @returns
 */
const InterventionsList = (props: IInterventionsListProps): ReactElement | null => {
  const { interventionItems, configFilters } = props;
  const [interventionListElements, setInterventionListElements] = useState<Interventie[]>();

  /**
   * Check if the effectivenessText is set and render the text element
   *
   * @param effectivenessText
   * @returns
   */
  const getEffectivenessText = (effectivenessText: string | undefined | null): ReactElement | null => {
    if (effectivenessText) {
      return (
        <p className="c-intervention-list__item__text">{ effectivenessText }</p>
      );
    }
    return null;
  };

  const shortenTextLength = (text: string) => {
    if (text.length > 256) {
      return `${text.substr(0, 256)}...`;
    }
    return text;
  };

  const getText = (text: string) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = text;
    return shortenTextLength(tmp.textContent || tmp.innerText || '');
  };

  const getHighlightedTheme = (intervention: Interventie) => {
    let style = '';

    if (configFilters) {
      const highlightedConfig = configFilters.find((con) => con.filters.some((fil) => fil.filterParameter === 'highlighted'));
      if (highlightedConfig) {
        const highlightedFilter = highlightedConfig.filters
          .find(((fil) => fil.filterParameter === 'highlighted'));
        if (highlightedFilter) {
          const linkedFilters = highlightedFilter.linkedFilters.map((li) => li.id);
          // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
          const isHighlightedFilter = linkedFilters.findIndex((value) => value === +intervention.code!) !== -1;

          style = isHighlightedFilter ? 'c-grid-tile__highlighted' : '';
        }
      }
    }

    return style;
  };

  /**
   *  Create the intervention item list for the interventions list
   *
   * @param interventionListItems
   * @returns
   */
  const renderInterventionListItems = (interventionListItems: Interventie[]) => interventionListItems.map(
    (intervention) => (
      <li
        className={`c-intervention-list__item ${getHighlightedTheme(intervention)}`}
        data-testid={intervention.code}
        key={intervention.code}
      >
        <Link to={`/InterventionDetails/${intervention.code}`}>
          <div className="d-flex">
            <div>
              <h4 className="c-intervention-list__item__title">{intervention.naam}</h4>
              { getEffectivenessText(intervention.erkenningsNiveau?.tekst) }
              <dl className="d-flex flex-wrap">
                <DescriptionItem
                  term="Organisatie"
                  description={intervention.organisatie?.naam}
                />
                <DescriptionItem
                  term="Doel"
                  description={getText(intervention.bAlgemeen?.doel || '')}
                />
              </dl>
            </div>
            <div className="c-intervention-list__item__effectiveness pl-2">
              <EffectivenessComponent recognitionLevel={intervention.erkenningsNiveau} />
            </div>
          </div>
        </Link>
      </li>
    ),
  );

  /**
   * Perform side effects in function components.
   * In this way it handle async functionality
   */
  useEffect(() => {
    setInterventionListElements(interventionItems);
  }, [interventionItems]);

  if (interventionListElements && interventionListElements.length > 0) {
    return (
      <ul className="c-intervention-list list-unstyled">
        { renderInterventionListItems(interventionListElements) }
      </ul>
    );
  }
  return null;
};

export default InterventionsList;
