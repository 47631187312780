import { IInnerHTML } from '../interfaces/InnerHTML';

/**
 * Create the object for the dangerouslySetInnerHTML, it's Reacts replacement for using innerHTML in the browser DOM
 *
 * @param innerHTML
 * @returns
 */
const createInnerHTML = (innerHTML: string | null | undefined): IInnerHTML => ({ __html: innerHTML || '' });

export default createInnerHTML;
