import getJsonFromDrupal from '../services/drupalService/drupalService';

/**
 * Get the mappings JSON from Drupal environment
 *
 * @param mappingFileName
 * @returns
 */
const getMappingFileConfiguration = async <T>(mappingFileName: string): Promise<T> => {
  const data = await getJsonFromDrupal(mappingFileName);
  return Promise.resolve(<T>data);
};

export default getMappingFileConfiguration;
