import React, {
  ReactElement, useCallback, useEffect, useState, KeyboardEvent, createRef,
} from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useLocation, useHistory } from 'react-router-dom';
import Accordion from './Accordion';
import InterventionsList from './InterventionsList';
import ErrorComponent from '../errorComponent/ErrorComponent';
import SearchBarComponent from '../searchBarComponent/SearchBarComponent';
import getMappingFileConfiguration from '../../utils/mapperHelper';
import { interventionsGetService } from '../../services/interventionsService/interventionsService';
import Interventie from '../../interfaces/intervention-api/interventie';
import { IInterventionsComponentProps } from '../../interfaces/InterventionsComponentProps';
import { IInterventionListResponse } from '../../interfaces/InterventionListResponse';
import { IUrlParams } from '../../interfaces/IUrlParams';
import { DEFAULT_APPLICATION_TEXT, LANGUAGE } from '../../constants/constants';

import './InterventionsComponent.scss';
import { IConfigMapping } from '../../interfaces/ConfigMapping';
import OverviewNavigationButton from '../OverviewNavigationButton/OverviewNavigationButton';
import ShareInterventionsButton from '../ShareInterventionsButton/ShareInterventionsButton';

/**
 * Render the interventions component
 *
 * @returns
 */
const InterventionsComponent = ({ mappingFileName }: IInterventionsComponentProps): ReactElement => {
  const location = useLocation();
  const history = useHistory();
  const { trackEvent } = useMatomo();
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [data, setData] = useState<IInterventionListResponse>();
  const [error, setError] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [configuration, setConfiguration] = useState<IConfigMapping>();
  const [pageSize, setPageSize] = useState(20);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [urlParameters, setUrlParameters] = useState<IUrlParams[]>([]);
  const interventionsResultRef = createRef<HTMLDivElement>();

  const {
    ERROR_MESSAGE_INTERVENTIONS,
    INTERVENTIONS_FILTERS_TITLE,
    INTERVENTIONS_HEADER_TITLE,
    INTERVENTIONS_HEADER_RESULTS,
    LOADING_MESSAGE_INTERVENTIONS,
    NAVIGATE_BTN_NEXT_TEXT,
    NAVIGATE_BTN_PREV_TEXT,
    OVERVIEW_NAVIGATION_BUTTON,
  } = DEFAULT_APPLICATION_TEXT[LANGUAGE];

  /**
   * Handles the navigation between intervention list pages
   *
   * @param pageNumber
   */
  const pageNavigationHandler = (pageNumber: number) => {
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.set('page', `${pageNumber}`);
    history.push({
      pathname: location.pathname,
      search: urlSearchParams.toString(),
    });
    interventionsResultRef.current?.scrollIntoView();
    const interventionListItems = document.getElementsByClassName('c-intervention-list__item');
    if (interventionListItems && interventionListItems.length > 0) {
      const firstItem = interventionListItems[0] as HTMLLIElement;
      const anchorLink = firstItem.firstElementChild as HTMLAnchorElement;
      anchorLink.focus();
    }
  };

  /**
   * Go to the previous page and reload the interventions list
   */
  const goToPreviousPage = () => {
    pageNavigationHandler(currentPageNumber - 1);
  };

  /**
   * Go to the next page reload the interventions list
   */
  const goToNextPage = () => {
    pageNavigationHandler(currentPageNumber + 1);
  };

  /**
   * Go to the page with the page number and reload the interventions list
   */
  const goToPage = (pageNumber: number) => {
    pageNavigationHandler(pageNumber);
  };

  /**
   * Check if previous button should be disabled or not
   *
   * @returns
   */
  const shouldPreviousButtonBeActive = () => currentPageNumber === 1;

  /**
   * Check if next button should be disabled or not
   *
   * @returns
   */
  const shouldNextButtonBeActive = () => {
    if (!data) {
      return false;
    }
    return (currentPageNumber - 1) * pageSize + pageSize >= data.count;
  };

  /**
   * Check if last page number should show or not
   *
   * @returns
   */
  const shouldLastPagesLinkBeActive = (level : number) => {
    if (!data) {
      return false;
    }
    return currentPageNumber < Math.floor((data.count / pageSize) - level);
  };

  const onPageNumberKeyDown = (event: KeyboardEvent<HTMLSpanElement>, pageNumber: number) => {
    const { key } = event;

    if (key === 'Enter' || key === ' ') {
      event.preventDefault();
      goToPage(pageNumber);
    }
  };

  /**
   * Renders the page link with page number on the bottom of the interventions list
   *
   * @returns
   */
  const renderPageNumber = (pageNumber: number) => (
    <span className="c-interventions__badge">
      <span
        onClick={() => goToPage(pageNumber)}
        onKeyDown={(event) => onPageNumberKeyDown(event, pageNumber)}
        role="button"
        tabIndex={0}
      >
        { pageNumber }
      </span>
    </span>
  );

  /**
   * Renders the page link with page number on the bottom of the interventions list
   *
   * @returns
   */
  const renderDots = () => (
    <span className="c-interventions__badge">...</span>
  );

  /**
   * Renders the numbers on the bottom of the interventions list
   * And it checks if it needs to show the previous and next badge with the page number
   *
   * @returns
   */
  const renderNumbersOfPages = () => (
    <>
      { currentPageNumber > 2 ? renderPageNumber(1) : null }
      { currentPageNumber > 3 ? renderDots() : null }
      { !shouldPreviousButtonBeActive() ? renderPageNumber(currentPageNumber - 1) : null }
      <span data-testid="badgePrimary" className="c-interventions__badge badge-primary">{ currentPageNumber }</span>
      { !shouldNextButtonBeActive() ? renderPageNumber(currentPageNumber + 1) : null }
      { shouldLastPagesLinkBeActive(1) ? renderDots() : null }
      { shouldLastPagesLinkBeActive(0) && data ? renderPageNumber(Math.floor((data.count / pageSize) + 1)) : null }
    </>
  );

  const trackUserEvent = useCallback((actionValue: string, categoryValue: string, nameValue: string) => {
    if (process.env.NODE_ENV !== 'development') {
      const te = {
        action: actionValue,
        category: categoryValue,
        name: nameValue,
      };
      trackEvent(te);
    }
  }, [trackEvent]);

  const trackUrlParams = useCallback((searchParams: URLSearchParams) => {
    const paramValues: Array<string> = [];
    searchParams.forEach((p) => paramValues.push(p));
    paramValues.sort();
    trackUserEvent('Click', 'Interventies Filter', paramValues.toString());
  }, [trackUserEvent]);

  /**
   * Method needs to be passed through as parameter to a child component
   * So it can update the state of this component through a child component
   * It will invoke the interventions service with the search term is receives as a parameter
   *
   * @param searchTermOnClick
   */
  const onSearchClick = (searchTermOnClick: string) => {
    const searchParams = new URLSearchParams(location.search);
    if (searchTermOnClick) {
      searchParams.set('titleSearch', searchTermOnClick);
      trackUrlParams(searchParams);
    } else {
      searchParams.delete('titleSearch');
    }
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  /**
   * Handles the onClick event on the interventions filter button and toggle the expanded state
   */
  const toggleAccordionOnClick = () => setExpanded(!expanded);

  useEffect(() => {
    document.title = INTERVENTIONS_HEADER_TITLE;
  }, [INTERVENTIONS_HEADER_TITLE]);

  /**
   * Perform side effects in function components.
   * In this way it handles async functionality.
   * If one of the parameters values changes for the interventionsGetService it will do a new request and update the DOM
   */
  useEffect(() => {
    if (((urlParameters.length > 0 && currentPageNumber > 0) || searchTerm.length > 0)) {
      if (loading) {
        setLoading(false);
        const fetchIntervention = async () => {
          try {
            const skipAmount = (currentPageNumber - 1) * pageSize;
            const response = await interventionsGetService(
              searchTerm,
              false,
              skipAmount,
              pageSize,
              ...urlParameters,
            );
            if (typeof response !== 'number') {
              setData(response as IInterventionListResponse);
            } else if (data != null) {
              setData({ count: 0, interventions: new Array<Interventie>() } as IInterventionListResponse);
            }
          } catch (err) {
            trackUserEvent('Click', 'Error', 'Get Service Interventies Component');
            setError(true);
          }
        };
        fetchIntervention();
      }
    }
  }, [loading, trackUserEvent, data, searchTerm, currentPageNumber, urlParameters, pageSize]);

  /**
   * Perform side effects in function components.
   * In this way it handles async functionality.
   * Get the filters JSON from Drupal on render of this component
   */
  useEffect(() => {
    if (mappingFileName) {
      const getMappingConfigAsync = async () => {
        try {
          const response = await getMappingFileConfiguration<IConfigMapping>(mappingFileName);
          setConfiguration(response);
          setPageSize(response.numberOfInterventions);
        } catch (err) {
          trackUserEvent('Click', 'Error', 'Get Interventies Component Mapping File');
          setError(true);
        }
      };
      getMappingConfigAsync();
    }
  }, [mappingFileName, trackUserEvent]);

  /**
   * A function to get the current search parameters from the current website location
   */
  const getUrlParams = useCallback((searchParams: URLSearchParams) => {
    const searchParametersEntries = Array.from(searchParams.entries());
    const newSearchParameters: IUrlParams[] = [];

    if (searchParametersEntries.length > 0 && configuration?.configFilters && configuration?.configFilters.length > 0) {
      searchParametersEntries.forEach((parameter) => {
        const key = parameter[0];
        const value = parameter[1];

        const filteredConfig = configuration?.configFilters.filter((f) => f.groupFilterParameter === key);
        filteredConfig.forEach((filConfig) => {
          const checkedFilterParams = filConfig.filters.find((checkedFilter) => checkedFilter.filterParameter === value);
          if (checkedFilterParams) {
            const checkedLinkedFilters = checkedFilterParams.linkedFilters;
            checkedLinkedFilters.forEach((checkedLinkedFilter) => {
              const searchParamIndex = newSearchParameters.findIndex((state) => state.name === checkedLinkedFilter.searchQuery);
              if (searchParamIndex === -1) {
                newSearchParameters.push({
                  name: checkedLinkedFilter.searchQuery,
                  searchIds: [checkedLinkedFilter.id],
                });
              } else {
                const searchParamsByFilterQuery = newSearchParameters.filter((state) => state.name === checkedLinkedFilter.searchQuery);
                const searchParamId = searchParamsByFilterQuery.findIndex((state) => state.searchIds.includes(checkedLinkedFilter.id));
                if (searchParamId === -1) {
                  newSearchParameters[searchParamIndex].searchIds.push(checkedLinkedFilter.id);
                }
              }
            });
          }
        });
      });
    }
    return newSearchParameters;
  }, [configuration]);

  /**
   * Perform side effects in function components.
   * In this way it handles async functionality.
   * Update the URL parameters when the filters are updated
   */
  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const urlParams = getUrlParams(searchParams);

      const pageNumber = searchParams.get('page');
      setCurrentPageNumber(pageNumber ? +pageNumber : 0);

      const titleSearch = searchParams.get('titleSearch');
      if (titleSearch) {
        setSearchTerm(titleSearch);
      } else {
        setSearchTerm('');
      }

      setUrlParameters(urlParams);
      setLoading(true);
      trackUrlParams(searchParams);
    } else {
      history.push({
        pathname: location.pathname,
        search: '?page=1&allFilter=allInterventions',
      });
    }
  }, [location, getUrlParams, trackUrlParams, history]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (error) {
    return (
      <>
        <OverviewNavigationButton
          navigateTo="/Overview"
          text={configuration?.backButtonText ?? OVERVIEW_NAVIGATION_BUTTON}
        />
        <ErrorComponent errorMessage={ERROR_MESSAGE_INTERVENTIONS} />
      </>
    );
  }

  return (
    <div className="c-interventions d-flex flex-wrap">
      <div className="c-interventions__overview__button-container">
        <div className="container pr-sm-3 pt-0">
          <div className="row">
            {
              configuration?.showBackButton
              && (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <OverviewNavigationButton
                    navigateTo="/Overview"
                    text={configuration.backButtonText ?? OVERVIEW_NAVIGATION_BUTTON}
                  />
                </div>
              )
            }
            <div className="col-lg-3 col-md-6 col-sm-6">
              <ShareInterventionsButton />
            </div>
          </div>
        </div>
      </div>
      <div className="c-interventions__sidebar" ref={interventionsResultRef}>
        <h3 className="c-interventions__sidebar__title">{ INTERVENTIONS_FILTERS_TITLE }</h3>
        <button
          aria-controls="c-interventions-accordion"
          aria-expanded={expanded}
          className="c-interventions__filter-btn btn btn-primary w-100"
          data-testid="filterButton"
          onClick={toggleAccordionOnClick}
          type="button"
        >
          { INTERVENTIONS_FILTERS_TITLE }
        </button>
        <div
          id="c-interventions-accordion"
          data-testid="interventionsAccordion"
          className={`c-interventions__accordion${expanded ? ' show' : ''}`}
        >
          <Accordion configFilters={configuration?.configFilters} />
        </div>
      </div>
      <div className="c-interventions__main d-flex flex-column">
        {
          configuration?.showSearchBar
          && (
            <div className="mb-4">
              <SearchBarComponent onSearchClick={onSearchClick} />
            </div>
          )
        }
        <div>
          <div className="c-interventions__header">
            <h3 className="c-interventions__header__title">{ INTERVENTIONS_HEADER_TITLE }</h3>
            <p className="c-interventions__header__results" role="status">
              {`${INTERVENTIONS_HEADER_RESULTS} ${data?.count || 0}`}
            </p>
          </div>
          {
            loading
              ? LOADING_MESSAGE_INTERVENTIONS
              : <InterventionsList interventionItems={data?.interventions} configFilters={configuration?.configFilters} />
          }
          <div className="c-interventions__footer d-flex justify-content-center">
            <button
              className="c-interventions__navigation-btn c-interventions__navigation-btn--prev btn"
              data-testid="previousButton"
              disabled={shouldPreviousButtonBeActive()}
              onClick={goToPreviousPage}
              type="button"
            >
              { NAVIGATE_BTN_PREV_TEXT }
            </button>
            <p className="c-interventions__navigation-text">
              { renderNumbersOfPages() }
            </p>
            <button
              className="c-interventions__navigation-btn c-interventions__navigation-btn--next btn"
              data-testid="nextButton"
              disabled={shouldNextButtonBeActive()}
              onClick={goToNextPage}
              type="button"
            >
              { NAVIGATE_BTN_NEXT_TEXT }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterventionsComponent;
