import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DEFAULT_APPLICATION_TEXT, LANGUAGE } from '../../constants/constants';
import './OverviewNavigationButton.scss';
import { IOverviewNavigationButtonProps } from '../../interfaces/OverviewNavigationButtonProps';

const OverviewNavigationButton = (props: IOverviewNavigationButtonProps): JSX.Element => {
  const {
    navigateTo,
    text,
  } = props;
  const history = useHistory();
  const {
    BACK_TEXT,
    BACK_ARIA_TEXT,
  } = DEFAULT_APPLICATION_TEXT[LANGUAGE];

  const getNavigateLocation = () => {
    if (navigateTo) {
      return <Link className="btn overview__button icon-terug" to={navigateTo}>{text}</Link>;
    }

    return (
      <button
        type="button"
        aria-label={BACK_ARIA_TEXT}
        className="btn overview__button icon-terug"
        onClick={() => history.goBack()}
      >
        {BACK_TEXT}
      </button>
    );
  };

  return getNavigateLocation();
};

export default OverviewNavigationButton;
