import React, { ReactElement } from 'react';
import GridTile from './GridTile';
import { IGridProps } from '../../interfaces/GridProps';

import './Grid.scss';
import { IFilter } from '../../interfaces/ConfigMapping';

/**
 * Render an individual Overview Grid
 *
 * @param props
 * @returns
 */
const Grid = (props: IGridProps): ReactElement => {
  const { overviewConfig } = props;

  /**
   * Return grid tiles if there is data to populate the tiles with
   */
  const getTiles = (gridTileItems: IFilter[] | undefined) => {
    if (gridTileItems && gridTileItems.length > 0) {
      return gridTileItems.map((tile) => {
        if (tile.showOnOverview) {
          return (
            <div key={tile.title} className="c-grid__tile col-lg-3 col-md-6 col-sm-12">
              <GridTile
                imgUrl={tile.imageUrl}
                groupFilterParameter={overviewConfig?.groupFilterParameter || ''}
                tileFilter={tile.filterParameter}
                title={tile.title}
                linkedFilters={tile.linkedFilters}
                mapsToFilters={tile.mapsTo}
                alternativeText={tile.alternativeTitle}
              />
            </div>
          );
        }
        return null;
      });
    }

    return null;
  };

  return (
    <div key={overviewConfig?.overviewTitle} className="c-grid">
      <h2 className="c-grid__title w-100">{overviewConfig?.overviewTitle}</h2>
      <div className="row">
        { getTiles(overviewConfig?.filters) }
      </div>
    </div>
  );
};

export default Grid;
