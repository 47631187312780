import React from 'react';
import dateFormat from '../../utils/dateFormat';
import EffectivenessComponent from '../effectivenessComponent/EffectivenessComponent';
import DescriptionItem from './DescriptionItem';
import renderHtml from '../../utils/renderHtml';
import DocumentList from './DocumentList';
import ContactList from './ContactList';
import { IInterventionDetailShowProps } from '../../interfaces/InterventionDetailProps';
import { DEFAULT_APPLICATION_TEXT, LANGUAGE } from '../../constants/constants';
import UnorderedListOfObjectText from './UnorderedListOfObjectText';
import ParentParticipationText from './ParentParticipationText';
import ThemesListItems from './ThemesListItems';
import ClassGroups from './ClassGroups';
import CostIndication from './CostIndication';
import BasisContactpersoon from '../../interfaces/intervention-api/basisContactpersoon';

const SchoolInterventionDetails = (props: IInterventionDetailShowProps): JSX.Element | null => {
  const {
    iId,
    interventionDetail,
  } = props;

  const {
    DATE_OF_RECOGNITION,
    DETAIL_DESCRIPTION_TITLE_CONTACT,
    DETAIL_DESCRIPTION_TITLE_SHORT,
    YES_TEXT,
    NO_TEXT,
  } = DEFAULT_APPLICATION_TEXT[LANGUAGE];

  const getYesNoText = (bool: boolean | undefined) => (bool ? YES_TEXT : NO_TEXT);

  const getPillars = () => interventionDetail.mAlgemeen?.pijlerKeuzes.map((pillar) => {
    const text = pillar.tekst?.toLocaleLowerCase() === 'educatie' ? 'Gezondheidseducatie' : pillar.tekst;
    return {
      id: pillar.id,
      tekst: text,
      code: pillar.code,
    };
  }) as [unknown];

  const mapBasisContactPersoon = () => {
    const basisContactPersoon: BasisContactpersoon = {
      iId: '0',
      contactpersoon1: {
        id: `${interventionDetail.sAlgemeen?.schoolContacten[0].id}` ?? null,
        naam: interventionDetail.sAlgemeen?.schoolContacten[0].naam ?? null,
        emailadres: interventionDetail.sAlgemeen?.schoolContacten[0].email ?? null,
        telefoonnummer: interventionDetail.sAlgemeen?.schoolContacten[0].telefoon ?? null,
        mobielNummer: interventionDetail.sAlgemeen?.schoolContacten[0].telefoon ?? null,
        organisatie: null,
        telefoonOrganisatie: null,
        code: null,
        emailadresOrganisatie: null,
        basisContactpersoon1Koppeling: null,
        basisContactpersoon2Koppeling: null,
        erkenningContactpersoon1Koppeling: [],
        erkenningContactpersoon2Koppeling: [],
        organisatieWebsites: null,
      },
      contactpersoon1Id: interventionDetail.sAlgemeen?.schoolContacten[0].id ?? 0,
      contactpersoon2: null,
      contactpersoon2Id: 0,
      interventie: null,

    };

    return basisContactPersoon;
  };

  const getSupportText = () => {
    const responseElement: React.ReactElement[] | null = [];
    if (interventionDetail.sAlgemeen) {
      if (interventionDetail.sAlgemeen.ondersteuningsLocatieKeuzes && interventionDetail.sAlgemeen.ondersteuningsLocatieKeuzes.length > 0) {
        responseElement.push(<UnorderedListOfObjectText objectList={interventionDetail.sAlgemeen.ondersteuningsLocatieKeuzes} />);
      }
      if (interventionDetail.sAlgemeen.ondersteuningslocatieVrij) {
        responseElement.push(<ul><li>{interventionDetail.sAlgemeen.ondersteuningslocatieVrij}</li></ul>);
      }
    }

    return (
      <>
        {responseElement}
      </>
    );
  };

  return (
    <div data-testid={iId}>
      <div className="d-flex">
        <div className="p-2">
          <h2 className="c-intervention__title">{interventionDetail?.naam}</h2>
          <p className="c-intervention__date">{ `${DATE_OF_RECOGNITION}: ${dateFormat(interventionDetail.erkenningsDatum)}` }</p>
        </div>
        <div className="p-2">
          <EffectivenessComponent recognitionLevel={interventionDetail?.erkenningsNiveau} />
        </div>
      </div>
      <div className="c-intervention-description d-flex flex-column">
        <div className="c-intervention-description__group">
          <div className="c-intervention-description__header">
            <h3 className="c-intervention-description__title">{ DETAIL_DESCRIPTION_TITLE_CONTACT }</h3>
          </div>
          <dl className="d-flex flex-wrap">
            <DescriptionItem
              term="Eigenaar"
              description={interventionDetail.organisatie?.naam}
            />
            <DescriptionItem
              term="Contactpersoon"
              description={<ContactList basicContactPerson={mapBasisContactPersoon()} />}
            />
          </dl>
        </div>
        <div className="c-intervention-description__group">
          <div className="c-intervention-description__header">
            <h3 className="c-intervention-description__title">{ DETAIL_DESCRIPTION_TITLE_SHORT }</h3>
          </div>
          <dl className="d-flex flex-wrap">
            <DescriptionItem
              term="Doel"
              description={renderHtml(interventionDetail.sAlgemeen?.doelSchool)}
            />
            <DescriptionItem
              term="Activiteit in het kort"
              description={renderHtml(interventionDetail.sAlgemeen?.omschrijvingSchool)}
            />
            <DescriptionItem
              term="Leerjaar"
              description={
                (interventionDetail.sAlgemeen || interventionDetail.sSpeciaalOnderwijs)
                && <ClassGroups schoolGeneral={interventionDetail.sAlgemeen} specialEducation={interventionDetail.sSpeciaalOnderwijs} />
              }
            />
            <DescriptionItem
              term="Materialen"
              description={
                interventionDetail.sAlgemeen
                && (
                <>
                  {
                    interventionDetail.sAlgemeen.materiaalTypeKeuzes
                    && <UnorderedListOfObjectText objectList={interventionDetail.sAlgemeen.materiaalTypeKeuzes} />
                  }
                  <br />
                  <div>{renderHtml(interventionDetail?.sAlgemeen?.toelichtingMaterialen)}</div>
                </>
                )
                }
            />
            <DescriptionItem
              term="Indicatie kosten"
              description={
                interventionDetail.sAlgemeen
                && <CostIndication schoolGeneral={interventionDetail.sAlgemeen} />
              }
            />
            <DescriptionItem
              term="Activiteit zelfstandig als school uit te voeren?"
              description={(
                <>
                  {getYesNoText(interventionDetail.sAlgemeen?.zelfstandig)}
                  {interventionDetail.sAlgemeen?.zelfstandig === false && renderHtml(interventionDetail.sAlgemeen.toelichtingOnzelfstandig)}
                </>
              )}
            />
            <DescriptionItem
              term="Door wie uit te voeren op school?"
              description={
                interventionDetail.sAlgemeen
                && <UnorderedListOfObjectText objectList={interventionDetail.sAlgemeen?.uitvoerderKeuzes} />
              }
            />
            <DescriptionItem
              term="Tijdsbesteding"
              description={
                interventionDetail.sAlgemeen
                && (
                <>
                  <div>{renderHtml(interventionDetail.sAlgemeen.tijdsbestek.tekst)}</div>
                  <div>{renderHtml(interventionDetail.sAlgemeen.toelichtingTijdsbestek)}</div>
                </>
                )
              }
            />
            <DescriptionItem
              term="Wanneer uit te voeren?"
              description={
                interventionDetail.sAlgemeen
                && <UnorderedListOfObjectText objectList={interventionDetail.sAlgemeen?.uitvoeringsMomentKeuzes} />
              }
            />
            <DescriptionItem
              term="Waar is ondersteuning beschikbaar?"
              description={getSupportText()}
            />
            <DescriptionItem
              term="Thema"
              description={<ThemesListItems baseThemes={interventionDetail.bThema} />}
            />
            <DescriptionItem
              term="Activiteit is gericht op"
              description={<UnorderedListOfObjectText objectList={getPillars()} />}
            />
            <DescriptionItem
              term="Ouderbetrokkenheid"
              description={(
                <ParentParticipationText
                  text={getYesNoText(interventionDetail.sAlgemeen?.ouderparticipatie)}
                  description={interventionDetail.sAlgemeen?.ouderparticipatieTypeVrij}
                />
              )}
            />
            <DescriptionItem
              term="Documenten"
              description={<DocumentList document={interventionDetail?.eDocument} />}
            />
          </dl>
        </div>
      </div>
    </div>
  );
};

export default SchoolInterventionDetails;
