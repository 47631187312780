import getData from '../../utils/fetchHelper';

/**
 * Get the specific JSON from Drupal based on the JSON path
 * Example of the drupal URL: '{domain}/sites/default/files/{jsonPath}'
 * @returns
 */
const getJsonFromDrupal = async <T>(mappingFileName: string): Promise<T | number> => {
  const url = `/sites/default/files/${mappingFileName}`;
  return getData(url);
};

export default getJsonFromDrupal;
