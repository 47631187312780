import React, { ChangeEvent, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ICustomCheckboxProps } from '../../interfaces/CustomCheckboxProps';

import './CustomCheckbox.scss';

/**
 * Render A custom checkbox
 * @returns
 */
const CustomCheckbox = (props: ICustomCheckboxProps): JSX.Element => {
  const {
    description,
    groupFilterParameter,
    searchFilterParameter,
    filters,
  } = props;
  const history = useHistory();
  const location = useLocation();

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const currentSearch = location.search;
    const searchParams = new URLSearchParams(currentSearch);
    const filterSearchParameters = searchParams.getAll(groupFilterParameter);
    const shouldBeChecked = filterSearchParameters.includes(searchFilterParameter);

    setChecked(shouldBeChecked);
  }, [location, groupFilterParameter, searchFilterParameter]);

  /**
   * Replace the white space with dashes so the id is valid for the HTML
   *
   * @param id
   * @returns
   */
  const htmlValidId = (id: string) => id.trim().replace(/\s+/g, '-').toLowerCase();

  /**
   * Remove a specific Item from the current URL
   *
   * @param groupFilterParam
   * @param searchFilterParam
   * @param currentSearch
   */
  const removeItemFromUrl = (groupFilterParam: string, searchFilterParam: string, currentSearch: string) => {
    const currentSearchNoQuestionMark = currentSearch.replace('?', '');
    const searchFilters = currentSearchNoQuestionMark.split('&');
    const filteredArray = searchFilters
      .filter((searchParamCombination) => searchParamCombination !== `${groupFilterParam}=${searchFilterParam}`);
    let newSearchParams = '?';
    newSearchParams += filteredArray.join('&');
    return newSearchParams;
  };

  /**
   * Handle the onChange event for the input fields
   * Append the new filter query to the URL
   * Remove from URL if unchecked
   *
   * @param event
   */
  const onFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const currentSearch = location.search;
    const searchFilter = filters?.find((fil) => fil.filterParameter === searchFilterParameter);
    if (searchFilter) {
      const mapsToFilters = searchFilter.mapsTo;
      let searchParams = new URLSearchParams();
      if ((searchFilterParameter === 'allInterventions' || searchFilterParameter === 'highlighted') && event.target.checked) {
        searchParams.append(groupFilterParameter, searchFilterParameter);
        searchParams.append('page', '1');
      } else if (event.target.checked) {
        let newSearchParams = removeItemFromUrl('allFilter', 'allInterventions', currentSearch);
        newSearchParams = removeItemFromUrl('themesFilter', 'highlighted', newSearchParams);
        searchParams = new URLSearchParams(newSearchParams);
        searchParams.set('page', '1');
        searchParams.append(groupFilterParameter, searchFilterParameter);
        if (mapsToFilters && mapsToFilters?.length > 0) {
          mapsToFilters.forEach((filter) => {
            searchParams.append(`${filter.groupFilterParameter}`, `${filter.filterParameter}`);
          });
        }
      } else {
        const newSearchParams = removeItemFromUrl(groupFilterParameter, searchFilterParameter, currentSearch);
        searchParams = new URLSearchParams(newSearchParams);

        if (mapsToFilters && mapsToFilters?.length > 0) {
          mapsToFilters.forEach((filter) => {
            searchParams.delete(filter.groupFilterParameter);
          });
        }

        const searchParamValue = Array.from(searchParams.values());
        const filtersFromValues = filters?.filter((fi) => searchParamValue.includes(fi.filterParameter));
        if (filtersFromValues) {
          filtersFromValues.forEach((ffk) => {
            const mapsToFromFfk = ffk.mapsTo;
            if (mapsToFromFfk) {
              mapsToFromFfk.forEach((map) => searchParams.append(`${map.groupFilterParameter}`, `${map.filterParameter}`));
            }
          });
        }
      }
      const searchParamsArray = Array.from(searchParams.keys()).filter((k) => k !== 'page');

      if (searchParamsArray.length === 0) {
        searchParams.append('allFilter', 'allInterventions');
      }
      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  };

  return (
    <label className="c-checkbox" htmlFor={`c-checkbox-${htmlValidId(description)}`}>
      {description}
      <input
        type="checkbox"
        id={`c-checkbox-${htmlValidId(description)}`}
        className="c-checkbox__input"
        checked={checked}
        onChange={onFilterChanged}
        data-testid="checkbox"
      />
      <span className="c-checkbox__check-mark" />
    </label>
  );
};

export default CustomCheckbox;
