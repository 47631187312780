/**
 * Make the keyword in the text bold, it's case insensitive
 *
 * @param text
 * @param keyword
 * @returns
 */
const makeKeywordsBold = (text:string | null, keyword:string | undefined): string | null => {
  if (text && keyword) {
    const re = new RegExp(`(${keyword})`, 'gi');
    return text.replace(re, '<strong>$1</strong>');
  }
  return text;
};

export default makeKeywordsBold;
