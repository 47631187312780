import React, {
  ChangeEvent,
  FormEvent,
  ReactElement,
  useEffect,
  useState,
  useRef,
  KeyboardEvent,
} from 'react';
import SuggestionList from './SuggestionList';
import ErrorComponent from '../errorComponent/ErrorComponent';
import { interventionsGetService } from '../../services/interventionsService/interventionsService';
import OnOutsideRefHook from '../../utils/onOutsideRefHook';
import DebounceHook from '../../utils/debounceHook';
import { ISearchBarComponentProps } from '../../interfaces/SearchBarComponentProps';
import Interventie from '../../interfaces/intervention-api/interventie';
import { IInterventionListResponse } from '../../interfaces/InterventionListResponse';
import { DEFAULT_APPLICATION_TEXT, LANGUAGE } from '../../constants/constants';

import './SearchBarComponent.scss';

/**
 * SearchBarComponent search the interventions on keywords and show suggestions
 *
 * @todo we need a error message component when the search request fails
 * @param props
 * @returns
 */
const SearchBarComponent = (props: ISearchBarComponentProps): ReactElement => {
  const { onSearchClick } = props;
  const refButton = useRef<HTMLButtonElement>(null);
  const refInput = useRef<HTMLInputElement>(null);
  const refSearchBar = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(false);
  const [focusSuggestionList, setFocusSuggestionList] = useState(-1);
  const [suggestionListItems, setSuggestionListItems] = useState<Interventie[]>();
  const [showSuggestionList, setShowSuggestionList] = useState(false);

  /**
   * Set the search term with the debounce hook, so the useEffect hook will only update after 300ms of inactivity of the searchTerm state
   */
  const debouncedValue = DebounceHook<string>(searchTerm, 300);

  const {
    ERROR_MESSAGE_SEARCH_INTERVENTIONS,
    SEARCH_BTN_TEXT,
    SEARCH_INPUT_PLACEHOLDER,
  } = DEFAULT_APPLICATION_TEXT[LANGUAGE];

  /**
   * Set the search bar with the outside hook and when the user click outside the component it will hide the suggestions list
   */
  OnOutsideRefHook(refSearchBar, setShowSuggestionList, false);

  /**
   * On value chang in the input element update the searchTerm
   * And empty the suggestionListItems when the value is less than 2 characters
   *
   * @param event
   */
  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value.length > 2) {
      setShowSuggestionList(true);
    } else {
      setShowSuggestionList(false);
    }
  };

  /**
   * Handle the click event of the search button
   * It will invoke the onSearchClick method from a parent component and empty the suggestionListItems
   */
  const onClickHandler = () => {
    onSearchClick(searchTerm);
  };

  /**
   * Handle the focus event of the input text field
   * If the input field is on focus, it will reset the focusSuggestionList state and show the suggestions list
   */
  const onFocusHandler = () => {
    setFocusSuggestionList(-1);
    if (searchTerm.length > 2) {
      setShowSuggestionList(true);
    }
  };

  /**
   * Handle the navigation for the suggestions list and update the search bar component accordingly
   * - on "escape" Closes the popup/suggestion list and returns focus to the textbox
   * - on "arrow down" Moves focus to and selects the next option in the popup/suggestion list. If focus is on the last option does nothing
   * - on "arrow up" Moves focus to and selects the previous option in the popup/suggestion list. If focus is on the first option returns focus to the textbox
   * - on "arrow right" Returns focus to the textbox without closing the popup and moves the input cursor one character to the right. If the input cursor is on the right-most character, the cursor does not move
   * - on "arrow left" Returns focus to the textbox without closing the popup and moves the input cursor one character to the left. If the input cursor is on the left-most character, the cursor does not move
   * - on "tab" navigate to search button and hide the suggestions list
   *
   * @param event
   */
  const onKeyDownHandler = (event: KeyboardEvent<HTMLElement>) => {
    const { key } = event;

    if (suggestionListItems && suggestionListItems.length > 0) {
      setShowSuggestionList(true);

      if (key === 'ArrowDown') {
        event.preventDefault();
        if (focusSuggestionList < suggestionListItems.length - 1) {
          setFocusSuggestionList(focusSuggestionList + 1);
        }
      }

      if (key === 'ArrowLeft' && refInput.current) {
        refInput.current.focus();
      }

      if (key === 'ArrowRight' && refInput.current) {
        refInput.current.focus();
      }

      if (key === 'ArrowUp' && focusSuggestionList > -1) {
        event.preventDefault();
        setFocusSuggestionList(focusSuggestionList - 1);

        if (focusSuggestionList === 0 && refInput.current) {
          refInput.current.focus();
        }
      }

      if (key === 'Escape') {
        event.preventDefault();
        refInput.current?.focus();
        setShowSuggestionList(false);
      }

      if (key === 'Tab' && focusSuggestionList !== -1) {
        event.preventDefault();
        refButton.current?.focus();
        setShowSuggestionList(false);
      }
    }
  };

  /**
   * On return key pressed when form is active is will invoke the search button function
   * And prevent refresh page when return key is pressed if the focus is on the form
   *
   * @param event
   */
  const onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onClickHandler();
  };

  /**
   * Perform side effects in function components.
   * In this way it handle async functionality
   */
  useEffect(() => {
    if (debouncedValue && searchTerm.length > 2) {
      const asyncArrowFunction = async () => {
        setError(false);
        try {
          const interventionResponse = await interventionsGetService(
            searchTerm,
            true,
            0,
            3,
          );
          if (typeof interventionResponse !== 'number') {
            const interventionsSearchList = interventionResponse as IInterventionListResponse;
            setSuggestionListItems(interventionsSearchList.interventions);
          } else {
            setSuggestionListItems([]);
          }
        } catch (err) {
          setError(true);
        }
      };
      asyncArrowFunction();
    }
  }, [debouncedValue, searchTerm]);

  return (
    <div
      className="c-search-bar"
      ref={refSearchBar}
    >
      <div
        // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
        role="combobox"
        aria-expanded={showSuggestionList}
        aria-owns={showSuggestionList ? 'suggestion-list' : ''}
        aria-haspopup="listbox"
      >
        <form
          className="c-search-bar__form form-inline d-flex flex-nowrap"
          data-testid="form-search"
          onSubmit={onSubmitHandler}
        >
          <label className="c-search-bar__label flex-grow-1" htmlFor="search">
            <span className="c-search-bar__input-icon" />
            <input
              id="search"
              className="form-control c-search-bar__input"
              type="text"
              autoComplete="off"
              data-testid="input-search"
              aria-autocomplete="list"
              aria-controls={showSuggestionList ? 'suggestion-list' : ''}
              ref={refInput}
              placeholder={SEARCH_INPUT_PLACEHOLDER}
              aria-label={SEARCH_INPUT_PLACEHOLDER}
              onChange={onChangeHandler}
              onKeyDown={onKeyDownHandler}
              onFocus={onFocusHandler}
            />
          </label>
          <button
            type="button"
            className="btn btn-primary c-search-bar__btn"
            data-testid="button-search"
            ref={refButton}
            onClick={onClickHandler}
          >
            { SEARCH_BTN_TEXT }
          </button>
        </form>
      </div>
      { error ? <ErrorComponent errorMessage={ERROR_MESSAGE_SEARCH_INTERVENTIONS} /> : null }
      { showSuggestionList && !error ? (
        <SuggestionList
          suggestionItems={suggestionListItems}
          searchTerm={searchTerm}
          setFocus={focusSuggestionList}
          onKeyDownHandler={onKeyDownHandler}
          ariaLabel={SEARCH_INPUT_PLACEHOLDER}
        />
      ) : null }
    </div>
  );
};

export default SearchBarComponent;
