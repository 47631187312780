import getData, { getDocument } from '../../utils/fetchHelper';
import {
  INTERVENTIONS_API_DOMAIN,
  INTERVENTIONS_DETAIL_API_URL,
  INTERVENTIONS_DOCUMENT_API_URL,
  INTERVENTIONS_SEARCH_API_URL,
} from '../../constants/constants';
import UrlParams from '../../interfaces/intervention-api/urlParams';
import { IInterventionListResponse } from '../../interfaces/InterventionListResponse';
import { InterventionDetailResponse } from '../../types/InterventionDetailResponse';

/**
 * Get all interventions from search terms
 *
 * @param searchTerm search interventions by search query
 * @param isTitleSearch if true search only in the intervention "naam" string
 * @param skipAmount Set this parameter with a number to skipAmount the first x amount of interventions
 * @param pageSize Set the size of the response from the interventions API
 * @param urlParams UrlParams to filter the interventions
 * @returns
 */
const interventionsGetService = async (
  searchTerm?: string,
  isTitleSearch = false,
  skipAmount = 0,
  pageSize = 25,
  ...urlParams: UrlParams[]
): Promise<IInterventionListResponse | number> => {
  const globalSearchParams = new URLSearchParams();

  if (searchTerm) {
    if (isTitleSearch) {
      globalSearchParams.append('titleSearch', searchTerm);
    } else {
      globalSearchParams.append('keywordSearch', searchTerm);
    }
  }

  globalSearchParams.append('skipAmount', `${skipAmount}`);
  globalSearchParams.append('pageSize', `${pageSize}`);
  urlParams.forEach((urlParam) => globalSearchParams.append(urlParam.name, encodeURI(urlParam.searchIds.join(';'))));
  const url = `${INTERVENTIONS_API_DOMAIN}${INTERVENTIONS_SEARCH_API_URL}?${globalSearchParams}`;

  return getData(url);
};

/**
 * Get one intervention from id
 *
 * @param code
 * @returns
 */
const interventionsIdGetService = async (code: string): Promise<InterventionDetailResponse> => {
  const url = `${INTERVENTIONS_API_DOMAIN}${INTERVENTIONS_DETAIL_API_URL}/${code}`;
  return getData(url);
};

/**
  * Get a document for an intervention
  *
  * @param id
  * @returns
  */
const interventionDocumentGetService = (id: string): Promise<Blob | number> => {
  const url = `${INTERVENTIONS_API_DOMAIN}${INTERVENTIONS_DOCUMENT_API_URL}/${id}`;
  return getDocument(url);
};

export {
  interventionsGetService,
  interventionsIdGetService,
  interventionDocumentGetService,
};
