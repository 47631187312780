import React from 'react';
import dateFormat from '../../utils/dateFormat';
import EffectivenessComponent from '../effectivenessComponent/EffectivenessComponent';
import DescriptionItem from './DescriptionItem';
import renderHtml from '../../utils/renderHtml';
import DocumentList from './DocumentList';
import ContactList from './ContactList';
import { DEFAULT_APPLICATION_TEXT, LANGUAGE } from '../../constants/constants';
import { IInterventionDetailShowProps } from '../../interfaces/InterventionDetailProps';

const HealthyLifeInterventionDetail = (props: IInterventionDetailShowProps): JSX.Element | null => {
  const {
    iId,
    interventionDetail,
  } = props;

  const {
    DATE_OF_RECOGNITION,
    DETAIL_DESCRIPTION_TITLE_CONTACT,
    DETAIL_DESCRIPTION_TITLE_IMPLEMENTATION,
    DETAIL_DESCRIPTION_TITLE_QUALITY,
    DETAIL_DESCRIPTION_TITLE_SHORT,
  } = DEFAULT_APPLICATION_TEXT[LANGUAGE];

  return (
    <div data-testid={iId}>
      <div className="d-flex">
        <div className="p-2">
          <h2 className="c-intervention__title">{interventionDetail?.naam}</h2>
          <p className="c-intervention__date">{ `${DATE_OF_RECOGNITION}: ${dateFormat(interventionDetail.erkenningsDatum)}` }</p>
        </div>
        <div className="p-2">
          <EffectivenessComponent recognitionLevel={interventionDetail?.erkenningsNiveau} />
        </div>
      </div>
      <div className="c-intervention-description d-flex flex-column">
        <div className="c-intervention-description__group">
          <div className="c-intervention-description__header">
            <h3 className="c-intervention-description__title">{ DETAIL_DESCRIPTION_TITLE_SHORT }</h3>
          </div>
          <dl className="d-flex flex-wrap">
            <DescriptionItem
              term="Doelgroep"
              description={renderHtml(interventionDetail?.eSamenvatting?.doelgroep)}
            />
            <DescriptionItem
              term="Doel"
              description={renderHtml(interventionDetail?.bAlgemeen?.doel)}
            />
            <DescriptionItem
              term="Aanpak in het kort"
              description={renderHtml(interventionDetail?.eSamenvatting?.aanpak)}
            />
          </dl>
        </div>
        <div className="c-intervention-description__group">
          <div className="c-intervention-description__header">
            <h3 className="c-intervention-description__title">{ DETAIL_DESCRIPTION_TITLE_IMPLEMENTATION }</h3>
          </div>
          <dl className="d-flex flex-wrap">
            <DescriptionItem
              term="Type organisatie"
              description={renderHtml(interventionDetail?.eUitvoering?.uitvoeringslocatie)}
            />
            <DescriptionItem
              term="Randvoorwaarden"
              description={renderHtml(interventionDetail?.eUitvoering?.randvoorwaarden)}
            />
            <DescriptionItem
              term="Materialen"
              description={renderHtml(interventionDetail?.eSamenvatting?.materiaal)}
            />
            <DescriptionItem
              term="Documenten"
              description={<DocumentList document={interventionDetail?.eDocument} />}
            />
          </dl>
        </div>
        <div className="c-intervention-description__group">
          <div className="c-intervention-description__header">
            <h3 className="c-intervention-description__title">{ DETAIL_DESCRIPTION_TITLE_QUALITY }</h3>
          </div>
          <dl className="d-flex flex-wrap">
            <DescriptionItem
              term="Erkenningsstatus"
              description={interventionDetail?.erkenningsNiveau?.tekst}
            />
            <DescriptionItem
              term="Datum erkenning"
              description={dateFormat(interventionDetail?.erkenningsDatum)}
            />
            <DescriptionItem
              term="Oordeel commissie"
              description={renderHtml(interventionDetail?.mAlgemeen?.oordeelCommissie)}
            />
            <DescriptionItem
              term="Interventie is beoordeeld door"
              description={interventionDetail?.mAlgemeen?.commissie?.tekst}
            />
            <DescriptionItem
              term="Onderzoek"
              description={renderHtml(interventionDetail?.eSamenvatting?.onderzoek)}
            />
          </dl>
        </div>
        <div className="c-intervention-description__group">
          <div className="c-intervention-description__header">
            <h3 className="c-intervention-description__title">{ DETAIL_DESCRIPTION_TITLE_CONTACT }</h3>
          </div>
          <dl className="d-flex flex-wrap">
            <DescriptionItem
              term="Eigenaar"
              description={interventionDetail?.organisatie?.naam}
            />
            <DescriptionItem
              term="Contactpersoon"
              description={<ContactList basicContactPerson={interventionDetail?.bContactpersoon} />}
            />
          </dl>
        </div>
      </div>
    </div>
  );
};

export default HealthyLifeInterventionDetail;
