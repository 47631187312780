import { useEffect, useState } from 'react';

/**
 * Hook that helps to limit that the component is re-rendered too many times on changing of a state
 *
 * @param value
 * @param delay
 * @returns
 */
const DebounceHook = <T>(value: T, delay?: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default DebounceHook;
