const getHeaders = (contentType: string) => ({
  'Content-Type': contentType,
  user_key: `${process.env.REACT_APP_API_KEY}` || '',
});

/**
 * Wrapper for the [Fetch API]{@link https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API} get method
 * And if it's a 200 response it will use teh [json()]{@link https://developer.mozilla.org/en-US/docs/Web/API/Response/json} method
 * If it's not 200 it will return the status code
 *
 * @param url
 * @returns
 */
const getData = async <R>(url: string): Promise<R | number> => {
  const response = await fetch(url, {
    method: 'GET',
    headers: getHeaders('application/json'),
  });

  if (response.status === 200) {
    return response.json();
  }
  return response.status;
};

const getDocument = async (url: string): Promise<Blob | number> => {
  const response = await fetch(url, {
    method: 'GET',
    headers: getHeaders('application/pdf'),
  });

  if (response.status === 200) {
    return response.blob();
  }
  return response.status;
};

export { getData as default, getDocument };
