import React, { ReactElement } from 'react';
import { DEFAULT_APPLICATION_TEXT, LANGUAGE } from '../../constants/constants';
import { IContactListProps } from '../../interfaces/ContactListProps';
import Contactpersoon from '../../interfaces/intervention-api/contactpersoon';

/**
 * Create the list of contacts for the intervention detail page
 *
 * @param props
 * @returns
 */
const ContactList = (props: IContactListProps): ReactElement | null => {
  const { basicContactPerson } = props;
  const {
    DETAIL_CONTACT_LIST_EMAIL,
    DETAIL_CONTACT_LIST_TEL,
  } = DEFAULT_APPLICATION_TEXT[LANGUAGE];

  /**
   * With the use of filter to remove any undefined values.
   * It is not possible to do this directly because Typescript doesn't update the type of the return value correctly after the filter function.
   * See more on the following issue {@link:https://github.com/microsoft/TypeScript/issues/20707|Array<T>.filter should be able to refine T|undefined to T}
   *
   * @param contactPerson
   * @returns
   */
  const isContactPerson = (contactPerson: Contactpersoon | null): contactPerson is Contactpersoon => !!contactPerson;

  /**
   * Create the list items for the contact list and checks if all the properties are set
   *
   * @param contactPersons
   * @returns
   */
  const contactListItem = (contactPersons: Contactpersoon[]): ReactElement[] => contactPersons.map((contactPerson) => {
    const {
      id,
      naam,
      emailadres,
      telefoonnummer,
      mobielNummer,
      telefoonOrganisatie,
    } = contactPerson;

    /**
     * Get a phone number what is set in the correct order
     */
    const nummer = [telefoonnummer, mobielNummer, telefoonOrganisatie].find((item) => item !== null);

    return (
      <li data-testid={id} key={id}>
        <ul className="list-unstyled">
          { naam ? (
            <li>{ naam }</li>
          ) : null }
          { emailadres ? (
            <li>
              { `${DETAIL_CONTACT_LIST_EMAIL}: ` }
              <a href={`mailto:${emailadres}`}>
                { `${emailadres}` }
                <span className="element-invisible sr-only"> (link opent standaard e-mail applicatie)</span>
              </a>
            </li>
          ) : null }
          { nummer ? (
            <li>
              { ` ${DETAIL_CONTACT_LIST_TEL}: ` }
              <a href={`tel:${nummer}`}>
                { `${nummer}` }
              </a>
            </li>
          ) : null }
        </ul>
      </li>
    );
  });

  if (basicContactPerson) {
    const contactPersons = [basicContactPerson.contactpersoon1, basicContactPerson.contactpersoon2]
      .filter(isContactPerson);

    return (
      <ul className="list-unstyled">
        { contactListItem(contactPersons) }
      </ul>
    );
  }

  return null;
};

export default ContactList;
