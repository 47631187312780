import React from 'react';
import { DEFAULT_APPLICATION_TEXT, LANGUAGE } from '../../constants/constants';

const ShareInterventionsButton = ():JSX.Element => {
  const {
    SHARE_TEXT,
  } = DEFAULT_APPLICATION_TEXT[LANGUAGE];

  const getMailToHref = () => (`mailTo:?body=${encodeURIComponent(window.location.toString())}`);

  return (
    <a
      href={getMailToHref()}
      className="btn overview__button icon-email w-100"
      title="Deel deze pagina via E-mail"
    >
      {SHARE_TEXT}
    </a>
  );
};

export default ShareInterventionsButton;
