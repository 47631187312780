import React, {
  KeyboardEvent, ReactElement, useState,
} from 'react';
import CustomCheckbox from './CustomCheckbox';
import { IFiltersProps } from '../../interfaces/FiltersProps';
import { DEFAULT_APPLICATION_TEXT, LANGUAGE } from '../../constants/constants';

import './Filters.scss';
/**
 * Render all filters for the interventions filter page
 *
 * @returns
 */
const Filters = ({
  title,
  searchParameter,
  filters,
  defaultExpanded,
  showScreenReaderDescriptionText,
}: IFiltersProps):ReactElement => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const { SCREENREADER_MESSAGE_SUBTHEMES_FILTER } = DEFAULT_APPLICATION_TEXT[LANGUAGE];

  const getControlTitle = () => {
    const regex = / /g;
    return title.replace(regex, '-').toLowerCase();
  };

  /**
   * Check if the state expanded is set and return a string
   *
   * @returns
   */
  const isExpanded = () => (expanded ? ' is-expanded' : '');

  /**
   * Handles the onClick event on the filter header button and toggle the expanded state
   *
   * @returns
   */
  const onClickHandler = () => setExpanded(!expanded);

  /**
   * Handle the keyPress event from the React control below
   * The function checks for the Return key and Spacebar key events and handles them like a click event
   *
   * @param event
   */
  const onKeyDownHandler = (event: KeyboardEvent<HTMLElement>) => {
    const { key } = event;

    if (key === 'Enter' || key === ' ') {
      event.preventDefault();
      onClickHandler();
    }
  };

  /**
   * Render all checkboxes for the filters based on mapping
   *
   * @returns
   */
  const renderCheckboxFilters = () => (filters.map((filter) => (
    <CustomCheckbox
      key={`${title}-${filter.title}-${filters.length}`}
      description={filter.title}
      groupFilterParameter={searchParameter}
      searchFilterParameter={filter.filterParameter}
      filters={filters}
    />
  )));

  const getScreenReaderText = () => {
    if (showScreenReaderDescriptionText) {
      return <span className="sr-only">{SCREENREADER_MESSAGE_SUBTHEMES_FILTER}</span>;
    }
    return null;
  };

  return (
    <div className="c-filter">
      <button
        data-testid="filter-header"
        id={`${getControlTitle()}`}
        className="c-filter__header d-flex justify-content-between btn"
        type="button"
        aria-expanded={expanded}
        tabIndex={0}
        aria-controls={`${getControlTitle()}-controls`}
        onKeyDown={onKeyDownHandler}
        onClick={onClickHandler}
      >
        <strong className="c-filter__header__title">
          {title}
        </strong>
        {getScreenReaderText()}
        <span className={`c-filter__expand-icon${isExpanded()}`} />
      </button>
      {expanded
      && (
        <div
          id={`${getControlTitle()}-controls`}
          className="c-filter__checkboxes"
          data-testid="checkboxFilters"
          role="region"
          aria-labelledby={`${getControlTitle()}`}
        >
            { renderCheckboxFilters() }
        </div>
      )}
    </div>
  );
};

export default Filters;
