import React from 'react';
import { Link } from 'react-router-dom';
import { IGridTileProps } from '../../interfaces/GridTileProps';

import './GridTile.scss';

/**
 * Render an individual Overview Grid Tile that links to the filter page
 *
 * @param props
 * @returns
 */
const GridTile = (props: IGridTileProps): React.ReactElement => {
  const {
    imgUrl,
    title,
    linkedFilters,
    groupFilterParameter,
    tileFilter,
    mapsToFilters,
    alternativeText,
  } = props;

  /**
   * Get all the sub categories and make one string out of it
   *
   * @returns
   */
  const getSubCategories = () => {
    if (linkedFilters && linkedFilters?.length > 0) {
      const subCategoriesString = linkedFilters.slice(0, 3).map((subCategory, index) => (
        `${subCategory.description}${linkedFilters.length > 1 && linkedFilters.length - 1 !== index ? ', ' : ''}`));
      if (linkedFilters.length > 3) { subCategoriesString.push('...'); }
      if (subCategoriesString.toString() !== '') {
        return <p className="c-grid-tile__text" data-testid="gridSubCategories">{ subCategoriesString }</p>;
      }
    }
    return null;
  };

  const getSubText = () => {
    if (alternativeText) {
      return <p className="c-grid-tile__text">{ alternativeText }</p>;
    }

    return getSubCategories();
  };

  /**
   * Check if title is set
   *
   * @returns
   */
  const getTitle = () => {
    if (title) {
      return <strong>{title}</strong>;
    }

    return null;
  };

  /**
   * Get the No Content style className
   *
   * @returns
   */
  const getTileContentNoCategoryStyle = () => {
    const hasNoDescription = linkedFilters && linkedFilters.length > 0
      && linkedFilters[linkedFilters.length - 1].description === '';
    if (hasNoDescription) {
      return ' c-grid-tile__content--no-categories justify-content-md-center';
    }
    return '';
  };

  const getTileSearchParams = () => {
    const searchParams = new URLSearchParams();
    searchParams.append('page', '1');
    searchParams.append(`${groupFilterParameter}`, `${tileFilter}`);
    if (mapsToFilters && mapsToFilters?.length > 0) {
      mapsToFilters.forEach((filter) => {
        searchParams.append(`${filter.groupFilterParameter}`, `${filter.filterParameter}`);
      });
    }
    return searchParams.toString();
  };

  const getGridTileStyle = () => (tileFilter === 'highlighted' ? 'c-grid-tile__highlighted' : '');

  return (
    <Link
      className={`text-decoration-none c-grid-tile ${getGridTileStyle()}`}
      to={{
        pathname: '/InterventionSearchResults',
        search: getTileSearchParams(),
      }}
    >
      <div className={`c-grid-tile__content d-flex flex-md-column ${getTileContentNoCategoryStyle()}`}>
        <img className="c-grid-tile__image align-self-center" src={imgUrl} alt="" />
        <div className="align-self-center">
          <h4 className="c-grid-tile__title" data-testid="gridTitle">{ getTitle() }</h4>
          { getSubText() }
        </div>
      </div>
    </Link>
  );
};

export default GridTile;
