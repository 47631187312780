import { DEFAULT_APPLICATION_TEXT, LANGUAGE } from '../constants/constants';

/**
 * Convert the ISO 8601 date format to the dd-mm-yyyy date format.
 * And if isoDateFormat is undefined returns fall back message.
 *
 * @param isoDateFormat {@link https://www.iso.org/iso-8601-date-and-time-format.html|ISO 8601}
 * @returns
 */
const dateFormat = (isoDateFormat: string | null | undefined): string => {
  if (isoDateFormat) {
    const date = new Date(isoDateFormat);
    const year = date.getFullYear();
    let month = date.getMonth() + 1 as number | string;
    let day = date.getDate() as number | string;

    if (day < 10) {
      day = `0${day}`;
    }

    if (month < 10) {
      month = `0${month}`;
    }

    return `${day}-${month}-${year}`;
  }
  return DEFAULT_APPLICATION_TEXT[LANGUAGE].DATE_FORMAT_UNKNOWN;
};

export default dateFormat;
