import React, { ReactElement } from 'react';
import IAttachmentWithFileName from '../../interfaces/AttachmentWithFileName';
import { IDocumentListProps } from '../../interfaces/DocumentListProps';
import Bijlage from '../../interfaces/intervention-api/bijlage';
import {
  interventionDocumentGetService,
} from '../../services/interventionsService/interventionsService';

/**
 * Create the list of documents for the intervention detail page
 *
 * @todo Need to check how to get the pdf
 * @returns
 * @param props
 */
const DocumentList = (props : IDocumentListProps): ReactElement | null => {
  const { document } = props;

  /**
   * Only pass the attachments with a filename
   *
   * @param attachment
   * @returns
   */
  const hasFilename = (attachment: Bijlage): attachment is IAttachmentWithFileName => !!attachment.fileName;

  const getDocument = (id: string, fileName: string) => {
    const asyncArrowFunction = async () => {
      try {
        const response = await interventionDocumentGetService(id);
        if (typeof response !== 'number') {
          const blob = new Blob([response], {
            type: 'application/json;charset=utf-8',
          });
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = window.document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `${fileName}`,
          );
          window.document.body.appendChild(link);
          link.click();

          // Clean up and remove the link
          // eslint-disable-next-line
          link.parentNode!.removeChild(link);
        }
      } catch (err) {
        // eslint-disable-next-line
        console.error(err);
      }
    };
    asyncArrowFunction();
  };

  /**
   * Create the list items for the document list and checks if all the properties are set
   *
   * @param attachments
   * @returns
   */
  const documentListItems = (attachments: Bijlage[]): ReactElement[] => attachments.filter(hasFilename).map((attachment) => {
    const {
      fileName,
      id,
      titel,
    } = attachment;

    return (
      <li data-testid={id} key={id}>
        <button type="button" className="btn btn-link" onClick={() => getDocument(id, fileName)}>
          { titel || fileName }
          <span className="icon-inherit file-type-icon-file-pdf-o" role="img" aria-label=" (externe pdf)" />
          <span className="element-invisible sr-only newwindow"> (PDF opent in een nieuw venster)</span>
        </button>
      </li>
    );
  });

  if (document?.bijlages) {
    return (
      <ul className="list-unstyled">
        {documentListItems(document.bijlages)}
      </ul>
    );
  }

  return null;
};

export default DocumentList;
