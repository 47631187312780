import React from 'react';
import { IParentParticipationTextProps } from '../../interfaces/ParentParticipationTextProps';

const ParentParticipationText = (props: IParentParticipationTextProps): JSX.Element | null => {
  const {
    text,
    description,
  } = props;

  if (text) {
    return (
      <div data-testid="parentParticipationText">
        <p>{text}</p>
        { description && <p data-testid="parentParticipationDescription">{description}</p> }
      </div>
    );
  }

  return null;
};

export default ParentParticipationText;
