import { MutableRefObject, useEffect } from 'react';

/**
 * Hook that detects the clicks outside of the passed ref and call the callback function
 *
 * @param ref
 * @param stateCallBack
 * @param args
 */
const OnOutsideRefHook = <T>(
  ref: MutableRefObject<null | HTMLElement>,
  stateCallBack: (value: T) => void,
  ...args: [T]
): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        stateCallBack(...args);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, stateCallBack, args]);
};

export default OnOutsideRefHook;
