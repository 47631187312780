import React, { ReactElement, useEffect, useState } from 'react';
import SuggestionListItem from './SuggestionListItem';
import Interventie from '../../interfaces/intervention-api/interventie';
import { ISuggestionListProps } from '../../interfaces/SuggestionListProps';

import './SuggestionList.scss';

/**
 * Create the suggestions list for the search bar component
 *
 * @param props
 * @returns
 */
const SuggestionList = (props: ISuggestionListProps):ReactElement | null => {
  const {
    suggestionItems,
    searchTerm,
    setFocus,
    onKeyDownHandler,
    ariaLabel,
  } = props;
  const [searchListElements, setSearchListElements] = useState<ReactElement[]>();

  /**
   * Create the suggestion item list for the suggestions list
   * And set the focus on the list item when the user navigates through the list with the keyboard
   *
   * @param suggestionListItems
   * @param keyword
   * @param setFocusIndex
   * @returns
   */
  const renderSuggestionListItems = (
    suggestionListItems: Interventie[],
    keyword: string,
    setFocusIndex: number,
  ) => suggestionListItems?.map(
    (intervention, index) => (
      <SuggestionListItem key={intervention.iId} intervention={intervention} keyword={keyword} focus={setFocusIndex === index} />
    ),
  );

  /**
   * Perform side effects in function components.
   * In this way it handle async functionality
   */
  useEffect(() => {
    if (suggestionItems) {
      setSearchListElements(renderSuggestionListItems(suggestionItems, searchTerm as string, setFocus));
    }
  }, [suggestionItems, searchTerm, setFocus]);

  if (searchListElements && searchListElements.length > 0) {
    return (
      <ul
        id="suggestion-list"
        className="c-suggestion-list list-unstyled"
        role="listbox"
        tabIndex={-1}
        onKeyDown={onKeyDownHandler}
        aria-label={ariaLabel}
      >
        { searchListElements }
      </ul>
    );
  }
  return null;
};

export default SuggestionList;
