import { IDefaultApplicationMessagesTranslationObject } from '../interfaces/DefaultApplicationMessagesTranslationObject';

const DEFAULT_APPLICATION_TEXT = {
  en: {
    DATE_FORMAT_UNKNOWN: 'unknown',
    DATE_OF_RECOGNITION: 'Date of recognition',
    DETAIL_CONTACT_LIST_EMAIL: 'email',
    DETAIL_CONTACT_LIST_TEL: 'phone',
    DETAIL_DESCRIPTION_TITLE_CONTACT: 'Contact',
    DETAIL_DESCRIPTION_TITLE_IMPLEMENTATION: 'Implementation',
    DETAIL_DESCRIPTION_TITLE_QUALITY: 'Quality and effectiveness',
    DETAIL_DESCRIPTION_TITLE_REFERENCES: 'References',
    DETAIL_DESCRIPTION_TITLE_SHORT: 'The intervention in brief',
    ERROR_MESSAGE_INTERVENTIONS: 'An error has occurred while loading the interventions',
    ERROR_MESSAGE_INTERVENTION_DETAIL: 'An error has occurred while loading the intervention',
    ERROR_MESSAGE_SEARCH_INTERVENTIONS: 'An error has occurred while searching the interventions',
    INTERVENTIONS_FILTERS_TITLE: 'Filters',
    INTERVENTIONS_HEADER_TITLE: 'Overview of selected interventions',
    INTERVENTIONS_HEADER_RESULTS: 'Results',
    LOADING_MESSAGE_INTERVENTIONS: 'Loading interventions',
    LOADING_MESSAGE_INTERVENTION_DETAIL: 'Loading the intervention',
    LOADING_MESSAGE_INTERVENTION_THEMES: 'Loading intervention themes',
    NAVIGATE_BTN_NEXT_TEXT: 'Next',
    NAVIGATE_BTN_PREV_TEXT: 'Previous',
    SEARCH_BTN_TEXT: 'Search',
    SEARCH_INPUT_PLACEHOLDER: 'Search for intervention',
    ERROR_MESSAGE_INTERVENTION_THEMES: 'An error has occurred while loading the intervention theme',
    YES_TEXT: 'Yes',
    NO_TEXT: 'No',
    SCREENREADER_MESSAGE_SUBTHEMES_FILTER: 'Linked sub-themes will be selected automatically when selecting a Theme.',
    OVERVIEW_NAVIGATION_BUTTON: 'To overview',
    OVERVIEW_TITLE: 'Interventions Overview',
    BACK_TEXT: 'Back',
    BACK_ARIA_TEXT: 'Back to previous page',
    SHARE_TEXT: 'Share interventions',
  },
  nl: {
    DATE_FORMAT_UNKNOWN: 'onbekend',
    DATE_OF_RECOGNITION: 'Datum erkenning',
    DETAIL_CONTACT_LIST_EMAIL: 'e-mail',
    DETAIL_CONTACT_LIST_TEL: 'telefoon',
    DETAIL_DESCRIPTION_TITLE_CONTACT: 'Contact',
    DETAIL_DESCRIPTION_TITLE_IMPLEMENTATION: 'Uitvoering',
    DETAIL_DESCRIPTION_TITLE_QUALITY: 'Kwaliteit en effectiviteit',
    DETAIL_DESCRIPTION_TITLE_REFERENCES: 'Verwijzingen',
    DETAIL_DESCRIPTION_TITLE_SHORT: 'De interventie in het kort',
    ERROR_MESSAGE_INTERVENTIONS: 'Er is een fout opgetreden tijdens het laden van de interventies',
    ERROR_MESSAGE_INTERVENTION_DETAIL: 'Er is een fout opgetreden tijdens het laden van de interventie',
    ERROR_MESSAGE_SEARCH_INTERVENTIONS: 'Er is een fout opgetreden tijdens het zoeken van de interventies',
    INTERVENTIONS_FILTERS_TITLE: 'Filters',
    INTERVENTIONS_HEADER_TITLE: 'Overzicht geselecteerde interventies',
    INTERVENTIONS_HEADER_RESULTS: 'Resultaten',
    LOADING_MESSAGE_INTERVENTIONS: 'Inladen van de interventies',
    LOADING_MESSAGE_INTERVENTION_DETAIL: 'Inladen van de interventie',
    LOADING_MESSAGE_INTERVENTION_THEMES: 'Inladen van de interventie thema\'s',
    NAVIGATE_BTN_NEXT_TEXT: 'Volgende',
    NAVIGATE_BTN_PREV_TEXT: 'Vorige',
    SEARCH_BTN_TEXT: 'Zoeken',
    SEARCH_INPUT_PLACEHOLDER: 'Zoek een interventie',
    ERROR_MESSAGE_INTERVENTION_THEMES: 'Er is een fout opgetreden tijdens het laden van de interventie thema',
    YES_TEXT: 'Ja',
    NO_TEXT: 'Nee',
    SCREENREADER_MESSAGE_SUBTHEMES_FILTER: 'Gekoppelde subthemas worden automatisch geselecteerd bij het selecteren van een Thema.',
    OVERVIEW_NAVIGATION_BUTTON: 'Naar overzicht',
    OVERVIEW_TITLE: 'Interventie Overzichten',
    BACK_TEXT: 'Terug',
    BACK_ARIA_TEXT: 'Navigeer terug naar het vorige pagina',
    SHARE_TEXT: 'Interventies delen',
  },
} as IDefaultApplicationMessagesTranslationObject;

const INTERVENTIONS_API_DOMAIN = process.env.REACT_APP_API_DOMAIN || '';
const INTERVENTIONS_SEARCH_API_URL = '/Interventions';
const INTERVENTIONS_DETAIL_API_URL = '/InterventionDetails';
const INTERVENTIONS_DOCUMENT_API_URL = '/InterventionDocument';

const LANGUAGE = document.documentElement.lang as string || 'nl';

const RECOGNITION_LEVELS = [
  {
    tekst: 'Goed beschreven',
    level: 1,
  },
  {
    tekst: 'Goed onderbouwd',
    level: 2,
  },
  {
    tekst: 'Eerste aanwijzingen voor effectiviteit',
    level: 3,
  },
  {
    tekst: 'Goede aanwijzingen voor effectiviteit',
    level: 4,
  },
  {
    tekst: 'Sterke aanwijzingen voor effectiviteit',
    level: 5,
  },
];

export {
  DEFAULT_APPLICATION_TEXT,
  INTERVENTIONS_API_DOMAIN,
  INTERVENTIONS_SEARCH_API_URL,
  INTERVENTIONS_DETAIL_API_URL,
  INTERVENTIONS_DOCUMENT_API_URL,
  LANGUAGE,
  RECOGNITION_LEVELS,
};
