/* eslint-disable max-len */
import React from 'react';
import { ICostIndicationProps } from '../../interfaces/CostIndicationProps';
import renderHtml from '../../utils/renderHtml';
import headingParagraphElement from '../../utils/headingParagraphElement';

const CostIndication = (props: ICostIndicationProps): JSX.Element | null => {
  const {
    schoolGeneral,
  } = props;

  const getGuid = () => (Date.now().toString(36) + Math.random().toString(36).substring(2));

  const getIndicationCostElements = () => {
    const elements = [];
    if (schoolGeneral) {
      if (schoolGeneral.indicatieKostenSo) {
        elements.push((
          <div key={getGuid()}>
            {headingParagraphElement('Kosten Speciaal Onderwijs', schoolGeneral.indicatieKostenSo.tekst ?? schoolGeneral.indicatieKostenSo.Tekst)}
            {renderHtml(schoolGeneral.toelichtingKostenSo)}
          </div>
        ));
      }
      if (schoolGeneral && schoolGeneral.indicatieKostenPo) {
        elements.push((
          <div key={getGuid()}>
            {headingParagraphElement('Kosten PO', schoolGeneral.indicatieKostenPo.tekst ?? schoolGeneral.indicatieKostenPo.Tekst)}
            {renderHtml(schoolGeneral.toelichtingKostenPo)}
          </div>
        ));
      }
      if (schoolGeneral.indicatieKostenVo) {
        elements.push((
          <div key={getGuid()}>
            {headingParagraphElement('Kosten VO', schoolGeneral.indicatieKostenVo.tekst ?? schoolGeneral.indicatieKostenVo.Tekst)}
            {renderHtml(schoolGeneral.toelichtingKostenVo)}
          </div>
        ));
      }
      if (schoolGeneral.indicatieKostenMbo) {
        elements.push((
          <div key={getGuid()}>
            {headingParagraphElement('Kosten MBO', schoolGeneral.indicatieKostenMbo.tekst ?? schoolGeneral.indicatieKostenMbo.Tekst)}
            {renderHtml(schoolGeneral.toelichtingKostenMbo)}
          </div>
        ));
      }
    }

    if (elements.length > 0) {
      return (
        <>
          {
            elements.map((el) => el)
          }
        </>
      );
    }

    return null;
  };

  return getIndicationCostElements();
};

export default CostIndication;
