import React, { ReactElement } from 'react';
import createInnerHTML from './createInnerHTML';

/**
 * Render HTML from string and place it in div element
 *
 * @param htmlString
 * @returns
 */
const renderHtml = (htmlString: string | null | undefined, isInline = false): ReactElement => {
  const htmlStringWithKey = createInnerHTML(htmlString);
  if (isInline) {
    return (
      // eslint-disable-next-line react/no-danger
      <span dangerouslySetInnerHTML={htmlStringWithKey} />
    );
  }
  return (
    // eslint-disable-next-line react/no-danger
    <div dangerouslySetInnerHTML={htmlStringWithKey} />
  );
};

export default renderHtml;
