import React, { ReactElement } from 'react';
import Filters from './Filters';
import { IAccordionProps } from '../../interfaces/AccordionProps';

import './Accordion.scss';

/**
 * Render Accordion
 *
 * @returns
 */
const Accordion = ({ configFilters }: IAccordionProps): ReactElement | null => {
  if (configFilters && configFilters.length > 0) {
    return (
      <div
        data-testid="filters-accordion"
        className="c-accordion"
      >
        {
          configFilters.map((configFilter) => {
            if (configFilter.showInFilters) {
              return (
                <Filters
                  key={`${configFilter.filtersTitle}-${configFilter?.filters.length}`}
                  searchParameter={configFilter.groupFilterParameter}
                  title={configFilter.filtersTitle}
                  filters={configFilter.filters}
                  defaultExpanded={configFilter.defaultExpanded}
                  showScreenReaderDescriptionText={configFilter.showScreenReaderDescriptionText}
                />
              );
            }
            return null;
          })
        }
      </div>
    );
  }
  return null;
};

export default Accordion;
